import {
  Entity,
  entityKindSchemaValidator,
  KindValidator,
} from '@backstage/catalog-model';
import schema from './ADR.v1.schema.json';

export type Status =
  | {
      type: 'Draft' | 'Review' | 'Approved' | 'Rejected' | 'Implemented';
    }
  | {
      type: 'Unknown';
      reason?: string; // Reason why the status is unknown
    };

/**
 * Backstage catalog ADR kind Entity. ADR is used
 * to store important decisions or proposals,
 * related to other Entities.
 *
 * @public
 */
export interface ADREntityV1 extends Entity {
  /**
   * The apiVersion string of the ADR.
   */
  apiVersion: 'deeplay.io/adr';
  /**
   * The kind of the entity
   */
  kind: 'ADR';
  /**
   * The specification of the ADR Entity
   */
  spec: {
    /**
     * Document title.
     */
    title: string;
    /**
     * Original document name.
     */
    documentName?: string;

    /**
     * Link to original document.
     */
    linksToPreviousVersions?: string[];

    /**
     * Optional date of the record.
     */
    recordDate?: string;

    /**
     * Deprecated: use statusInfo instead.
     */
    status?: string;

    /**
     * Status of the record.
     */
    statusInfo?: Status;

    /**
     * Optional list of references to related entities.
     */
    relatedEntityRefs?: string[];

    authors?: string[];

    /**
     * Optional path to the document at the source,
     * depending on source type could be repository name or path at Google Drive.
     */
    path?: string;
  };
}

const validator = entityKindSchemaValidator(schema);

/**
 * Entity data validator for {@link ADREntityV1}.
 *
 * @public
 */
export const ADREntityV1Validator: KindValidator = {
  async check(data: Entity) {
    return validator(data) === data;
  },
};

/**
 * Typeguard for filtering entities and ensuring adr entities
 * @public
 */
export const isADREntityV1 = (entity: Entity): entity is ADREntityV1 =>
  entity.apiVersion === 'deeplay.io/adr' && entity.kind === 'ADR';
