import {Entity, stringifyEntityRef} from '@backstage/catalog-model';
import {
  OverflowTooltip,
  ResponseErrorPanel,
  Table,
  TableColumn,
} from '@backstage/core-components';
import {
  EntityRefLinks,
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import {ADREntityV1} from '@internal/backstage-plugin-adr-common';
import {Chip, useTheme} from '@material-ui/core';
import React, {useMemo} from 'react';
import {IconLink, IntegrationIcon} from './IconLink';
import {CustomChip} from './CustomChip';
import {useApi, configApiRef} from '@backstage/core-plugin-api';
import {getStatusColor} from './AdrCard';

const PAGE_SIZE = 10;

function getColumns(originalEntity: Entity): TableColumn<ADREntityV1>[] {
  return [
    {
      title: 'Name',
      field: 'metadata.name',
      width: '30%',
      filtering: true,
      render: entity => (
        <EntityRefLinks entityRefs={[entity]} defaultKind="Component" />
      ),
    },
    {
      title: 'Title',
      field: 'spec.title',
      width: '30%',
      filtering: true,
      render: ({spec: {title}}) => <OverflowTooltip text={title} />,
    },
    {
      title: 'Path',
      field: 'entity.spec.path',
      width: '300px',
      render: entity => {
        const link = entity.metadata.links
          ? entity.metadata.links[0]
          : {url: ''};
        const path = entity.spec?.path?.toString();

        if (!link.url && !path) {
          return null;
        }
        return (
          <div style={{minWidth: `300px`}}>
            <IconLink
              href={link.url || ''}
              Icon={() => IntegrationIcon(link.url, {height: 20, width: 20})}
              text={path}
            />
          </div>
        );
      },
    },
    {
      title: 'Status',
      field: 'spec.statusInfo.type',

      highlight: true,
      render: ({spec}) => {
        const {palette} = useTheme();
        const configApi = useApi(configApiRef);
        const docsUrl = configApi.getOptionalString('adr.docsUrl');
        if (!spec?.statusInfo) {
          return null;
        }
        const statusInfo = spec?.statusInfo;
        return (
          <CustomChip
            color={getStatusColor(statusInfo, palette)}
            content={statusInfo?.type || 'Unknown'}
            url={docsUrl}
            tooltipText={
              statusInfo?.type === 'Unknown'
                ? statusInfo.reason ?? 'Status not set'
                : undefined
            }
          />
        );
      },
    },
    {
      title: 'Record Date',
      field: 'spec.recordDate',
      width: '10%',
      filtering: false,
    },
    {
      title: 'Relation type',
      field: 'relations',
      width: '10%',
      render: ({relations}) => {
        const relevantRelations = relations?.filter(
          relation => relation.targetRef === stringifyEntityRef(originalEntity),
        );
        return (
          <>
            {relevantRelations?.map(relation => (
              <Chip
                key={relation.type}
                label={relation.type}
                color="primary"
                size="small"
              />
            ))}
          </>
        );
      },
    },
  ];
}

export const AdrListCard = () => {
  const {entity} = useEntity();

  const relatedAdrs = useRelatedEntities(entity, {kind: 'adr'});

  if (relatedAdrs.error) {
    return <ResponseErrorPanel error={relatedAdrs.error} />;
  }

  const columns = useMemo(() => getColumns(entity), [entity]);

  return (
    <Table<ADREntityV1>
      title="Related Architecture Decision Records"
      options={{
        search: false,
        pageSize: PAGE_SIZE,
        padding: 'dense',
        sorting: true,
        draggable: false,
        paging: true,
        filtering: true,
        filterCellStyle: {padding: '0 16px 0 20px'},
      }}
      columns={columns}
      data={(relatedAdrs.entities as ADREntityV1[]) || []}
    />
  );
};
