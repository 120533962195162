import {colors, Link, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  chip: {
    padding: '5px 12px',
    width: 'fit-content',
    borderRadius: '24px',
  },
});

type CustomChipProps = React.PropsWithChildren<{
  content: string;
  color?: string;
  tooltipText?: string;
  url?: string;
}>;
export const CustomChip = ({
  content,
  color,
  url,
  tooltipText,
}: CustomChipProps) => {
  const css = useStyles();
  if (!tooltipText) {
    return (
      <Typography
        variant="caption"
        className={css.chip}
        style={{
          backgroundColor: color || colors.grey[400],
        }}
      >
        {content}
      </Typography>
    );
  }
  return (
    <Tooltip
      arrow={true}
      interactive={true}
      leaveDelay={1500}
      title={
        url ? (
          <div>
            <Typography>{tooltipText}</Typography>
            <Link target="_blank" rel="noopener" href={url}>
              <Typography>Learn more</Typography>
            </Link>
          </div>
        ) : (
          <Typography>{tooltipText}</Typography>
        )
      }
      placement="top"
    >
      <Typography
        variant="caption"
        className={css.chip}
        style={{
          backgroundColor: color || colors.grey[400],
        }}
      >
        {content}
      </Typography>
    </Tooltip>
  );
};
